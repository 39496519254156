@import '../../../component-styles.scss';

.DisplayGroup {
  min-height: 42px;
  background-color: rgba(255, 249, 234, 1);
  border-radius: 4px;
  .GlobeIcon {
    color: rgba(242, 201, 76, 1);
  }
}
