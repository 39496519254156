.Spinner {
  animation: loading-logo-spin infinite 1.5s linear;
}

@keyframes loading-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
