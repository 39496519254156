@import '../../../../component-styles.scss';

.AttendeeFormResponses {
  background-color: $blue-lighter;
  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 24px;
    width: 0;
    height: 0;
    border: 19px solid transparent;
    border-bottom-color: $blue-lighter;
    border-top: 0;
    margin-left: -14px;
    margin-top: -14px;
  }
}
