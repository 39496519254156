@import '../component-styles.scss';

.Disabler {
  position: relative;
}

.Disabler--Blocker {
  background-color: $white;
  bottom: 0;
  left: 0;
  opacity: 0.65;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $zindex-disabler;
}
