@import '../../../../component-styles.scss';

.CalendarButton {
  height: 80px;
}

@include media-breakpoint-down(xs) {
  .CalendarButton {
    padding-top: 16px;
    padding-bottom: 16px;
    .description {
      display: none;
    }
  }
}
