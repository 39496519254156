@import '../component-styles.scss';

.Switch {
  background-color: $gray-300;
  border-radius: 0.5rem;
  cursor: pointer;
  height: 1rem;
  opacity: 0.9;
  position: relative;
  width: 2.25rem;
  -webkit-appearance: none;
  outline: none;

  &:before {
    background-color: $white;
    border-color: $gray-300;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.0625rem;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(51, 56, 62, 0.03);
    content: '';
    height: 1rem;
    position: absolute;
    transform: scale(1.3);
    width: 1rem;

    transition: left 0.1s linear;
    left: 0;
  }

  &:checked {
    background-color: $primary;

    &:before {
      left: 1.125rem;
    }
  }
}
