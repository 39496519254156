// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff

.modal-open {
  // Kill the scroll on the body
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Container that the modal scrolls within
.modal {
  height: 100%;
  left: 0;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $zindex-modal;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  margin: unset;
  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;
  position: relative;
  width: auto;

  // When fading in the modal, animate it to slide down
  .modal.fade & {
    @include transition($modal-transition);
    transform: $modal-fade-transform;
  }
  .modal.show & {
    transform: $modal-show-transform;
  }

  // When trying to close, animate focus to scale
  .modal.modal-static & {
    transform: $modal-scale-transform;
  }
}

.modal-dialog-scrollable {
  display: flex; // IE10/11
  max-height: subtract(100%, $modal-dialog-margin * 2);

  .modal-content {
    max-height: subtract(100%, $modal-dialog-margin * 2);
    overflow: hidden;
  }

  .modal-header,
  .modal-footer {
    flex-shrink: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.modal-dialog-centered {
  align-items: center;
  display: flex;

  // Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
  &::before {
    display: block; // IE10
    height: subtract(100vh, $modal-dialog-margin * 2);
    height: min-content; // Reset height to 0 except on IE
    content: '';
  }

  // Ensure `.modal-body` shows scrollbar (IE10/11)
  &.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .modal-content {
      max-height: none;
    }

    &::before {
      content: none;
    }
  }
}

// Actual modal
.modal-content {
  background-clip: padding-box;
  background-color: $modal-content-bg;
  border: unset;
  color: $modal-content-color;
  // Remove focus outline from opened modal
  outline: 0;
  // counteract the pointer-events: none; in the .modal-dialog
  pointer-events: auto;
  // NOTE
  // We apply fixed & inset-0 to create the layout
  // in which the header/footer are fixed and the modal body are
  // scrollable.
  // This prevents very wonky behavior on iOS/Safari.
  @apply tw-fixed tw-inset-0 md:tw-relative;
  @apply tw-flex tw-flex-col;
  @apply tw-w-full;
  @apply tw-overflow-y-auto tw-overflow-x-hidden md:tw-overflow-y-visible;
}

// Modal background
.modal-backdrop {
  background-color: $modal-backdrop-bg;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: $zindex-modal-backdrop;

  // Fade for backdrop
  &.fade {
    opacity: 0;
  }
  &.show {
    opacity: $modal-backdrop-opacity;
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  align-items: center; // so the close btn always stays on the upper right corner
  background-color: $white;
  border-bottom: $modal-header-border-width solid $modal-header-border-color;
  display: flex;
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  padding: 1rem;

  > .modal-title {
    color: $gray-800;
    font-size: 1.125rem;
  }

  > svg {
    margin-right: map-get($spacers, 12);
    color: $success;
  }

  .close {
    color: $gray-600;
    font-size: 2rem;
    font-weight: normal;
    padding: $modal-header-padding;
    // auto on the left force icon to the right even when there is no .modal-title
    margin: (-$modal-header-padding-y) (-$modal-header-padding-x)
      (-$modal-header-padding-y) auto;
  }
}

// Title text within header
.modal-title {
  line-height: $modal-title-line-height;
  margin-bottom: 0;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when there should be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem;
  position: relative;
}

.modal-body p:last-child {
  margin-bottom: 0;
}

// Footer (for actions)
.modal-footer {
  align-items: center; // vertically center
  background-color: $white;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
  padding: 1rem;
  width: 100%;

  &:empty {
    display: none;
  }

  // Place margin between footer elements
  // This solution is far from ideal because of the universal selector usage,
  // but is needed to fix https://github.com/twbs/bootstrap/issues/24800
  > * {
    margin: $modal-footer-margin-between / 2;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  .modal {
    align-items: center;
    height: 100%;
    width: 100%;

    &.show {
      display: flex !important;
    }

    > .modal-dialog {
      flex: 1;
      max-height: 100%;

      > .modal-content {
        margin: 1rem 0;
      }
    }
  }
  .modal-dialog {
    margin: $modal-dialog-margin-y-sm-up auto;
    max-width: $modal-md;
    width: auto;
  }
  .modal-dialog-scrollable {
    max-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

    .modal-content {
      max-height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
      overflow: hidden;
    }
  }
  .modal-dialog-centered {
    min-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

    &::before {
      height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
      height: min-content;
    }
  }
  .modal-content {
    border: $modal-content-border-width solid $modal-content-border-color;
    min-height: unset;
    @include border-radius($modal-content-border-radius);
    @include box-shadow($modal-content-box-shadow-sm-up);
    // NOTE
    // Reset values used on mobile to achieve tablet/desktop layout
    bottom: unset;
    left: unset;
    position: relative;
    right: unset;
    top: unset;
  }
  .modal-backdrop {
  }
  .modal-header {
    padding: $modal-header-padding;
    @include border-top-radius($modal-content-inner-border-radius);
  }
  .modal-title {
  }
  .modal-body {
    overflow-x: unset;
    overflow-y: unset;
    padding: $modal-inner-padding;
  }
  .modal-footer {
    padding: $modal-inner-padding - $modal-footer-margin-between / 2;
    position: relative;
    @include border-bottom-radius($modal-content-inner-border-radius);
  }
  .modal-sm {
    max-width: $modal-sm;
  }
}

@include media-breakpoint-up(lg) {
  .modal-lg,
  .modal-xl {
    max-width: $modal-lg;
  }
}

@include media-breakpoint-up(xl) {
  .modal-xl {
    max-width: $modal-xl;
  }
}
