@import '../component-styles.scss';

.SettingsModalWebhooksSection {
  .SettingsModalWebhooksSection--Webhook {
    &:last-child {
      hr {
        display: none;
      }
    }
  }
}
