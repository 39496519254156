@import '../component-styles.scss';

// This was copy/pasted from here and then modified.
// https://cdn.jsdelivr.net/npm/shepherd.js@8.3.1/dist/css/shepherd.css

$tour-box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.25);

.shepherd-footer {
  border-bottom-left-radius: $popover-border-radius;
  border-bottom-right-radius: $popover-border-radius;
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem 1rem;
}
.shepherd-footer .shepherd-button:last-child {
  margin-right: 0;
}
.shepherd-cancel-icon {
  background: transparent;
  border: none;
  color: hsla(0, 0%, 50.2%, 0.75);
  font-size: 2em;
  line-height: $h3-font-size;
  cursor: pointer;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: color 0.5s ease;
}
.shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, 0.75);
}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: hsla(0, 0%, 50.2%, 0.75);
}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, 0.75);
}
.shepherd-title {
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
}
.shepherd-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top-left-radius: $popover-border-radius;
  border-top-right-radius: $popover-border-radius;
  padding: 1rem 1rem 0;
}

.shepherd-text {
  padding: 1rem;

  p:last-child {
    margin-bottom: 0;
  }
}

.shepherd-content {
  border-radius: $popover-border-radius;
  outline: none;
  padding: 0;
  background-color: white;
}
.shepherd-element {
  background: #fff;
  border-radius: $popover-border-radius;
  box-shadow: $tour-box-shadow;
  max-width: 400px;
  opacity: 0;
  outline: none;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
  width: 100%;
  z-index: $zindex-tour + 1;
}
.shepherd-enabled.shepherd-element {
  opacity: 1;
  visibility: visible;
}
.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.shepherd-element,
.shepherd-element *,
.shepherd-element :after,
.shepherd-element :before {
  box-sizing: border-box;
}
.shepherd-arrow,
.shepherd-arrow:before {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: -1;
}
.shepherd-arrow:before {
  content: '';
  transform: rotate(45deg);
  background: #fff;
}
.shepherd-element[data-popper-placement^='top'] > .shepherd-arrow {
  bottom: -8px;
}
.shepherd-element[data-popper-placement^='bottom'] > .shepherd-arrow {
  top: -8px;
}
.shepherd-element[data-popper-placement^='left'] > .shepherd-arrow {
  right: -8px;
}
.shepherd-element[data-popper-placement^='right'] > .shepherd-arrow {
  left: -8px;
}
.shepherd-element.shepherd-centered > .shepherd-arrow {
  opacity: 0;
}
.shepherd-element.shepherd-has-title[data-popper-placement^='bottom']
  > .shepherd-arrow:before {
  background-color: white;
  box-shadow: $tour-box-shadow;
  border: 1px solid $gray-300;
}
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none;
}
.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
  width: 100vw;
  z-index: $zindex-tour;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: 0.5;
  transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all;
}
