@import '../component-styles.scss';

.DateInput {
  .dropdown-toggle {
    display: flex;
  }

  button {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
