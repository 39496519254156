@import '../component-styles.scss';

.ReschedulableTimesButton {
  .SelectableButton {
    color: $primary;
    width: 100%;

    &.success {
      color: $gray-700;
    }
  }
}
