@import '../component-styles.scss';

.MeetingTypeCard {
  min-height: 14rem;

  transition: box-shadow 0.1s linear;
  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }

  .card-body {
    cursor: pointer;
  }
}

.MeetingTypeCard--color {
  flex: 0 0 1.125rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
}

.MeetingTypeCard--link {
  color: $gray-500;
  display: none;
  flex: 0 0 1.125rem;
  line-height: 0;
}

.MeetingTypeCard:hover .MeetingTypeCard--link {
  display: inline;
}
