@import '../component-styles.scss';

.ProfileSwitcher {
  // This positions the logo inline with the container
  // below it and removes some of the whitespace on
  // the right.
  .dropdown-toggle.nav-link {
    display: flex;
    justify-content: flex-start;
  }

  // Remove the left padding from the icon so it
  // is vertically aligned with the column.
  .dropdown-toggle.nav-link {
    padding-left: unset;
  }

  // We add this min-width to keep the SearchInput
  // from jumping down in size as the list shrinks
  // to items with potentially less width.
  .SearchInput {
    min-width: 12.5rem;
  }

  .dropdown-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: $font-weight-normal;

    // Make sure the logos are always the
    // same height & width.
    img,
    svg {
      height: 2.2rem;
      width: 2.2rem;
    }
    p {
      margin-bottom: 0%;
      max-width: 9rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  // We do this to prevent bumping when switching
  // between profiles with logos & those without.
  .ProfileSwitcher-logo {
    height: 2.25rem;
    width: 2.25rem;
    img {
      max-height: 2rem;
    }
    border-radius: 6.25rem !important;
    padding: 1px;
    background-color: white;

    filter: drop-shadow(0.0625rem 0.0625rem 0.0625rem rgba(0, 0, 0, 0.25));
  }

  // this controls the dropdown item child components styles
  .item-check {
    margin-left: 2.5rem;
    margin-right: 0%;
    width: 1.7rem !important;
    height: 1.7rem !important;
  }
  .item:hover {
    background-color: $blue-lightest !important;
    color: $gray-900 !important;
  }
}

// This removes the active background color from
// the dropdown when selected.
.TopNav {
  .ProfileSwitcher.dropdown.show {
    background-color: unset;
  }
}
