@import '../component-styles.scss';

.SchedulingPagePicker {
  .SchedulingPagePicker--GroupLabel {
    padding: 0 0.75rem 0.5rem 0.75rem;

    > * {
      font-size: $font-size-sm !important;
      text-transform: uppercase;
    }
  }
}
