@import '../component-styles.scss';

.SelectableCard {
  cursor: pointer;
  transition: box-shadow 0.1s linear;

  &:hover {
    box-shadow: 0 0 0.9375rem rgba(0, 0, 0, 0.1);
  }
}
