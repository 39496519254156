@import '../../../../component-styles.scss';

.MeetingDetailSummary {
  .MoreButton {
    margin-right: -10px !important;
    button {
      border: none;
      svg {
        color: $gray-500;
      }
    }
  }
}
