.UpdateMeetingTypeModalLocationSection {
  .accordion {
    > .card {
      // This removes the card-header border-bottom if the accordion/card
      // is not in a selected state.
      &:not(.tw-selected) {
        .card-header {
          border-bottom: unset;
        }
      }
    }
  }
}
