@import '../../../component-styles.scss';

.Drawer-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  z-index: $zindex-modal;
  display: flex;
  justify-content: flex-end;
  overflow-y: auto;
  outline: none;
}

.Drawer {
  background-color: $white;
  box-shadow: -0.625rem 0px 0.625rem rgba(0, 0, 0, 0.05);
  animation-timing-function: ease-out;
  min-height: 100%;
  width: 100%;

  // This helps anchor any absolutely positioned elements in the Drawer.
  position: relative;
}

.Drawer-spinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Drawer-wrapper {
  width: 100%;
}

.Drawer-close {
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
  border-bottom-left-radius: $border-radius-lg;
}

@include media-breakpoint-up(sm) {
  .Drawer-wrapper-md {
    width: 31.25rem;
  }
}

.Drawer-appear-active {
  animation: slide-in 0.5s forwards;
}

.Drawer-exit-active {
  animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

.modal-backdrop-appear-active {
  animation: fade-in 0.5s forwards;
}

.modal-backdrop-exit-active {
  animation: fade-out 0.5s forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
