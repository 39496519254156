@import '../../../../component-styles.scss';

$footer-height: 78px;

.OnboardingShell {
  background-color: $white;
  .left {
    .content {
      margin-bottom: $footer-height;
    }
    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: $footer-height;
    }
  }
  @include media-breakpoint-up(lg) {
    .left {
      max-width: 683px;
    }
  }
}
