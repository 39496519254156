@import '../component-styles.scss';

.MeetingsListItem {
  border-left-width: 0.1875rem !important;

  &:hover {
    cursor: pointer;
    // We only want to apply this styling
    // onHover because if it is applied all
    // the time the actionable elements from
    // a MeetingListItem below will z-index
    // above the dropdown-menu.
    .MeetingsListItem--Body--Actions,
    .MoreButton {
      z-index: 1;
    }
    .MeetingsListItem--RescheduleButton,
    .MeetingsListItem--CancelButton {
      display: flex !important;
    }
  }

  .MeetingsListItem--Time {
    border-right: 0.0625rem solid $gray-300;
    flex: 0 0 11.125rem;
  }
}

.MeetingsListItem--status--cancelled {
  border-left-color: $danger !important;

  .MeetingsListItem--Time,
  .MeetingsListItem--Body--Meta {
    text-decoration: line-through;
  }
}

.MeetingsListItem--status--confirmed {
  border-left-color: $success !important;
}

.MeetingsListItem--status--past {
  border-left-color: $gray-500 !important;
}

.MeetingsListItem--status--pending {
  border-left-color: $warning !important;
}
