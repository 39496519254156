@import '../component-styles.scss';

.Toast {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  width: 25rem;
  max-width: 100%;
  background: #ffffff;
  border: 0.0625rem solid $gray-300;
  box-sizing: border-box;
  box-shadow: 0px 0.1875rem 0.3125rem rgba(0, 0, 0, 0.1);
  border-radius: 0.625rem;
}

.Toastify__toast {
  background-color: transparent !important;
}

.Toastify__toast-body {
  max-width: 100%;
}

.Toast--Close {
  cursor: pointer;
  color: $gray-500;
}
