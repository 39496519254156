@import '../component-styles.scss';

.MoreButton {
  // Removes toggle arrow
  .dropdown-toggle::after {
    content: unset;
  }

  // Mimic the padding of IconButton
  > .btn > svg {
    margin: 0 -0.25rem;
  }
}
