@import '../component-styles.scss';

.ImageInput {
  svg {
    opacity: 0;
    transition: $transition-fade;
    background: rgba(0, 0, 0, 0.3);
    color: $white;
  }

  &:hover svg {
    opacity: 1;
  }
}
