@import '../component-styles.scss';

.MeetingTypeVisibilityPicker {
  .dropdown-item:hover {
    svg,
    small {
      color: $white !important;
    }
  }
}
