@import '../../../../component-styles.scss';

.NotesTextInput {
  .IconTextInput {
    svg {
      color: $gray-400 !important;
    }
  }
  .RichTextEditor {
    margin: 0px;
    .DraftEditor-editorContainer {
      div {
        max-height: 112px;
      }
    }
  }

  .RichTextEditorButton {
    border-right: 1px solid $gray-400;
    color: $gray-600 !important;
  }
}
