@import '../component-styles.scss';

.MeetingsPageDateFilter {
  .MeetingsPageDateFilter--Back {
    &:hover {
      // We do not want the hover effect
      // applied to the "Back" button in the
      // custom picker menu.
      background-color: unset;
    }
  }
  // We apply these styles to override the overrides being
  // applied by DropdownItem since we are inheriting the API
  // from it into Button.
  .MeetingsPageDateFilter--ApplyCustomRange {
    background-color: $primary !important;
    color: $white !important;

    &:hover {
      background-color: #0089f7 !important;
    }
  }
}
