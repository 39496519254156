.IconTextInput {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  > svg {
    position: absolute;
    left: 0.7rem;
  }

  > input {
    padding-left: 2.25rem;
  }
}
