@import '../component-styles.scss';

.PortraitList {
  > li {
    margin-left: -0.5rem;

    &:first-child {
      margin-left: unset;
    }
  }
}
