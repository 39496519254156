@import '../component-styles.scss';

.MeetingTypesPage {
  .SchedulingPagePicker {
    @apply tw-max-w-[17.5rem] tw-flex-1;
    .PickerMenu {
      // NOTE: Why?
      // This is here to prevent the menu from only being the
      // width of the Select, which currently due to the layout
      // is really small.
      @apply tw-min-w-[17.5rem] tw-box-border;
    }
  }
}

.MeetingTypesPage--header {
  .SearchInput {
    width: 15rem;
  }
}
