@import '../component-styles.scss';

.RichTextEditorControls {
  align-items: center;
  border-left: $input-border-width solid $input-border-color;
  border-right: $input-border-width solid $input-border-color;
  border-top: $input-border-width solid $input-border-color;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: flex;
}
