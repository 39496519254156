@import '../component-styles.scss';

.MeetingsListDateBlock {
  .weekday {
    color: $gray-600;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    letter-spacing: 0.4px;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
  }

  .calendar-date {
    color: $gray-800;
    font-size: 1rem;
    font-weight: bold;
  }
}
