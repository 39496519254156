@import '../component-styles.scss';

.ReschedulableTimesPicker {
  height: 100%;
  .ReschedulableTimesPicker--date {
    color: $gray-600;
    font-weight: $font-weight-bold;
    line-height: 1.6;
    text-transform: uppercase;
  }

  .ReschedulableTimesPicker--time {
    padding-top: 0.4375rem;
    .col-sm-4 {
      padding: 0.3125rem;
    }
  }

  .ReschedulableTimesPicker--no-times {
    height: 100%;

    .col-sm-12 {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h4 {
        color: $gray-600;
        padding-top: 0.25rem;
      }

      p {
        color: $gray-500;
      }
    }
  }
}
