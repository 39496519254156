@import '../component-styles.scss';

.OnboardingPages {
  .OnboardingPages--ConferenceProviders {
    max-width: 67.25rem;
    .card {
      cursor: pointer;
    }
  }

  .OnboardingPages--InviteMembers {
    width: 40rem;
    max-width: 100%;
  }

  .form-group {
    .row {
      .col {
        .card {
          cursor: pointer;
          max-width: 21.75rem;
        }
      }
    }
  }

  .media {
    img {
      border: 0.0625rem solid $gray-400;
      border-radius: 0.3125rem;
    }

    .ImageInput,
    .ImageInput--overlay {
      height: 4rem;
      width: 4rem;

      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

.OnboardingPages--Modals {
  .CalendarDot {
    border-radius: 50%;
    flex: 0 0 0.75rem;
    height: 0.75rem;
    width: 0.75rem;
  }
  .Switch {
    flex: 0 0 2.25rem;
  }
}
