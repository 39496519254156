@import '../../../../component-styles.scss';

.OnboardFooter {
  color: $gray-500;
  width: 100%;
  @include media-breakpoint-down(xs) {
    .row {
      text-align: center;
      .col-sm-4 {
        margin-top: 10px;
      }
    }
  }
}
