@import '../component-styles.scss';

.DatePicker {
  // TODO: Remove this once we have better layouts
  padding: 1rem;

  // TODO: move this into a global style
  * {
    outline: none !important;
  }

  // Top level container
  .DayPicker {
    display: inline-block;
    font-weight: $font-weight-bold;
    width: 15rem;
  }

  .DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    user-select: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Footer {
    padding-top: 0.5em;
  }

  // Month container
  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .DayPicker-Month {
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0.25rem;
    width: 100%;
    margin: 0;
    user-select: none;
  }

  // Month heading
  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: left;

    > div {
      font-weight: $font-weight-bold;
    }
  }

  // Month navigation bar
  .DayPicker-NavBar {
    position: absolute;
    top: 0;
    right: 0;
  }

  // Month navigation buttons
  .DayPicker-NavButton {
    display: inline-block;
    cursor: pointer;
    position: static;
    margin: 0;
    width: 9px;
    height: 12px;
  }

  // Previous month button
  .DayPicker-NavButton--prev {
    background-image: url(../assets/icn-arrow-left.svg);

    &:active {
      background-image: url(../assets/icn-arrow-left-dark.svg);
    }
  }

  // Next month button
  .DayPicker-NavButton--next {
    background-image: url(../assets/icn-arrow-right.svg);

    &:active {
      background-image: url(../assets/icn-arrow-right-dark.svg);
    }
  }

  // When both buttons are present, move them apart
  .DayPicker-NavButton--prev + .DayPicker-NavButton--next {
    margin-left: 1.75rem;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.4rem 0;
    color: #60666c;
    text-align: center;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  // Day of month
  .DayPicker-Day {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    border-radius: 0.25rem;
    padding: 0.4rem 0;

    &:after {
      position: absolute;
      text-align: center;
      left: 0;
      right: 0;
      bottom: 0;
      color: #8a9199;
      letter-spacing: 0.05rem;
    }
  }

  // Day that has a business rating 1-3
  .DayPicker-Day--busy1:after {
    content: '.';
  }
  .DayPicker-Day--busy2:after {
    content: '..';
  }
  .DayPicker-Day--busy3:after {
    content: '...';
  }

  // Selected day
  .DayPicker-Day--selected {
    background-color: $primary !important;
    color: white;

    &:after {
      color: white;
    }
  }

  .DayPicker-Day--start:not(.DayPicker-Day--end) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .DayPicker-Day--range {
    border-radius: 0;
    opacity: 0.5;
  }

  .DayPicker-Day--end:not(.DayPicker-Day--start) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  // Day not in the current month, unless it's selected
  .DayPicker-Day--outside:not(.DayPicker-Day--selected) {
    color: rgba(138, 145, 153, 0.6);
  }

  // Today, unless selected
  .DayPicker-Day--today:not(.DayPicker-Day--selected) {
    color: $primary;
  }

  // When hovering, unless disabled or selected
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
    background-color: rgba(224, 226, 229, 0.6);
  }

  // When disabled
  .DayPicker-Day--disabled {
    color: #e0e2e5;
    cursor: default;
  }
}
