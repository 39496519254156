@import '../../../../component-styles.scss';

.MeetingDetailAttendeeUnit {
  .MoreButton {
    button {
      border: none;
      svg {
        color: $gray-500;
      }
    }
  }
}

.MeetingDetailAttendeeUnit.cancelled {
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}
