// List Groups

@mixin list-group-item-variant($state, $background, $color) {
  .list-group-item-#{$state} {
    color: theme-color($state);
    background-color: $white;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: theme-color($state);
        // background-color: darken($background, 5%);
      }

      &.active {
        color: $white;
        background-color: $color;
        border-color: $color;
      }
    }
  }
}

@mixin center-column {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin center-row {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin center-column-between {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin center-row-between {
  align-items: center;
  display: flex;
  justify-content: center;
}
