@import '../component-styles.scss';

.RemoveButton {
  border-radius: $btn-border-radius-sm;
  border: 0;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.25rem;
  background-color: transparent;
  color: $gray-600;

  &:hover {
    color: $gray-900;
    background-color: $gray-200;
  }

  > img {
    width: 100%;
    height: 100%;
  }
}
