.LoadingButton {
  position: relative;

  &.loading {
    > .Spinner {
      animation: loading-logo-spin infinite 1.5s linear;
      opacity: 1;
    }

    > .LoadingButton--content {
      opacity: 0;
    }
  }

  & > .Spinner {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -0.65rem;
    margin-left: -0.65rem;
  }
}

.LoadingButton--content {
  display: flex;
}

@keyframes loading-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
