.ToastContainer {
  .Toastify__toast-container {
    width: inherit;
  }

  .Toastify__toast--default {
    background: none;
  }

  .Toastify__toast {
    margin-bottom: 0rem;
    box-shadow: unset;
  }
}
