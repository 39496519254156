@import '../component-styles.scss';

.Portrait {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  border: 0.0625rem solid white;
  box-shadow: 0px 0.0625rem 0.0625rem rgba(0, 0, 0, 0.1);
}

.Portrait--image {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.Portrait--initials {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-200;
  color: $gray-600;
  text-transform: uppercase;
}
