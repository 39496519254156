@import '../component-styles.scss';

.ColorPicker {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
  flex-direction: row;
}

.ColorPicker-item {
  border-radius: $border-radius-sm;
  cursor: pointer;
  height: 1.75rem;
  width: 1.75rem;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    display: none;
  }
}

.ColorPicker-item-selected > img {
  display: inline;
}
