@import '../../../../component-styles.scss';

.MeetingDetailTabButton {
  height: 39px;
  cursor: pointer;
  border-radius: 30px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
}
.MeetingDetailTabButton.active {
  background-color: $pink;
  h6 {
    color: $white;
    font-weight: $font-weight-bolder;
  }
}

.MeetingDetailTabButton.non-active {
  background-color: $gray-100;
  h6 {
    color: $gray-900;
    font-weight: $font-weight-bold;
  }
}
