@import '../component-styles.scss';

// NOTE: YOU MUST USE `!important` to override emotion!!!
// If we do not place the important flag on our styles the
// custom classes will be ignored by the defaults applied
// by emotion, the internal CSS library being used by react-select

.PickerControl {
  display: flex;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  min-height: 2.5rem;
}

.PickerControl--focused {
  border-color: $input-focus-border-color;
  box-shadow: $input-focus-box-shadow;

  .PickerSingleValue,
  .PickerPlaceholder {
    opacity: 0;
  }

  .PickerInput {
    opacity: 1;
  }
}

.PickerGroupHeading {
  display: flex !important;
  justify-content: space-between;
  color: $gray-700 !important;
  font-size: $font-size-sm !important;
  letter-spacing: 0.0063rem;

  .btn {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
}

.PickerSingleValue {
  display: flex;
  align-items: center;
}

.PickerValueContainer {
}

.PickerInput {
  opacity: 0;
}

.PickerMenu {
  z-index: $zindex-select-menu !important;
}

.PickerPlaceholder {
  align-items: center;
  color: $gray-500 !important;
  display: flex;
}

.PickerMultiValueContainer {
  align-items: center;
  background-color: lighten($primary, 38%);
  border-radius: $border-radius-sm;
  display: flex;
  padding: 0.3125rem 0.375rem;
  margin: 0.125rem 0.25rem 0.125rem 0;
}

.PickerMultiValueLabel {
  align-items: center;
  display: flex;
  margin-right: 0.1875rem;
}

.PickerMultiValueRemove {
  cursor: pointer;
  color: $gray-500;

  &:hover {
    color: $gray-600;
  }
}

.PickerNoOptionsMessage {
  padding: 0.3125rem 0.375rem;
}
