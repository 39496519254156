@import '../../../../component-styles.scss';

.SlugInput {
  .inner-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .form-control:focus {
    box-shadow: none;
  }
  .error {
    background-color: rgba(205, 42, 42, 0.1);
  }
}
