@import '../component-styles.scss';

.ErrorScreen {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  max-width: 100vw;
}
