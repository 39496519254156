@import '../../../../component-styles.scss';

.MeetingDetailNoteUnit {
  .time-container {
    font-size: 12px;
    line-height: 19px;
  }
  .MoreButton {
    margin-right: -10px !important;
    button {
      border: none;
      svg {
        color: $gray-500;
      }
    }
    .icon {
      color: $gray-700 !important;
    }
  }
}
