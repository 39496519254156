@import '../component-styles.scss';

.TopBar {
  background-color: $white;
  border-bottom: 0.0625rem solid #e0e2e5;
  display: flex;
  min-height: 4.375rem;

  > div {
    display: flex;
    align-items: center;
  }
}
