@import '../component-styles.scss';

.RichTextEditor {
  border-radius: 0.25rem;
  margin-bottom: 2.25rem;

  .DraftEditor-root {
    border: $input-border-width solid $input-border-color;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .public-DraftEditor-content {
    height: 10rem !important;
    overflow-y: auto;
    padding: 1rem;
  }

  .public-DraftEditorPlaceholder-root {
    padding: 1rem;
  }
}
