@import '~bootstrap/scss/functions';
@import './_variables.scss';
@import '~bootstrap/scss/mixins';
@import './_mixins.scss';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import './_modal.scss';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

// ----------------------------------
// Tailwind
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  .tw-highlight {
    background-color: #fff9ea;
  }
  .tw-selected {
    @apply tw-border tw-border-bsSuccess tw-shadow-selected tw-transition-shadow tw-ease-linear tw-duration-100 #{!important};
  }
}
// ----------------------------------

h5 {
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
}

h6 {
  font-family: $font-family-base;
  color: $gray-600;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

// This takes all of the colors and creates text-color classes for them
@each $color, $value in $colors {
  @include text-emphasis-variant('.text-#{$color}', $value, true);
}

@each $color, $value in $grays {
  // Create background grays
  .background-gray-#{$color} {
    background-color: $value;
  }

  // Create text grays
  .text-gray-#{$color} {
    color: $value;
  }

  .border-gray-#{$color} {
    border-color: $value;
  }
}

// Space between
@each $size, $length in $spacers {
  .space-between-#{$size}:not(.flex-column) > * {
    margin-left: $length !important;
    margin-right: 0 !important;

    &:first-child {
      margin-left: 0 !important;
    }
  }

  .space-between-#{$size}.flex-column > * {
    margin-top: $length !important;
    margin-bottom: 0 !important;

    &:first-child {
      margin-top: 0 !important;
    }
  }
}

// Global Overrides
* > {
  outline: 0 !important;
}

[role='button'] {
  cursor: pointer;
}

body {
  background-color: $gray-100;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

strong {
  font-weight: $font-weight-bold;
}

// Global Input overrides
.form-control:focus {
  outline: 0 !important;
}

.badge-secondary {
  background-color: $gray-600 !important;
}

// Buttons

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  > svg,
  > img {
    margin-right: 0.3125rem;
  }
}

.btn:focus {
  box-shadow: none !important;
}

[class*='btn-outline'] {
  font-weight: $font-weight-bold;
  border-color: $gray-400 !important;
  background-color: $white;

  &:hover {
    background-color: $white;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background-color: $gray-200;
  }
}

.btn-outline-primary {
  &:hover {
    color: $primary;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $primary;
  }
}

.btn-outline-danger {
  &:hover {
    color: $danger;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $danger;
  }
}

.btn-outline-success {
  &:hover {
    color: $success;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $success;
  }
}

.btn-outline-secondary {
  &:hover {
    color: $secondary;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $secondary;
  }
}

.btn-link {
  padding: 0;
  border: 0;
  line-height: inherit;
  display: inline;
  vertical-align: inherit;
}

// Card Overrides

.card {
  box-shadow: $box-shadow-sm;

  margin-bottom: $spacer;
  &:last-child {
    margin-bottom: 0;
  }
}
.card-header {
  align-items: center;
  background-color: unset;
  display: flex;

  .card-title {
    margin-bottom: unset;
  }

  > img:first-child,
  > svg:first-child {
    color: #084c83;
    margin-right: map-get($spacers, 12);
  }
}

// Form Label Override
.form-label {
  font-weight: $font-weight-bold;
}

.form-label + .form-text {
  // this is a hack to swallow up some of the margin-bottom that form-label wants
  // to put on form-text when they sit next to each other.
  margin-top: -0.25rem;
}

.form-group:last-child {
  margin-bottom: unset;
}

.form-text {
  color: $gray-600;
  font-size: $font-size-base;
}

.col-form-label {
  text-align: right;
}

@include media-breakpoint-only(xs) {
  .col-form-label {
    text-align: left;
  }
}

// List groups

.list-group {
  border-color: $list-group-border-color;
  border-width: 0.0625rem 0 !important;
  border-style: solid;

  > hr {
    margin: 0.25rem 0;
  }
}

.list-group-item {
  // This needs !important to override the
  // `.list-group-item + .list-group-item` rule.
  border-top-width: 0.0625rem !important;
  &:first-child {
    border-top-width: 0 !important;
  }

  > svg {
    margin-right: 0.5rem;
  }
}

.list-group-item-action {
  cursor: pointer;
}

// Links

a.text-muted {
  text-decoration: underline;
}

.btn-link[disabled] {
  color: $gray-600 !important;
}

// Removes caret from dropdown.var
.no-caret {
  .dropdown-toggle::after {
    content: unset;
  }
}

.text-small {
  font-size: $font-size-sm;
}

.text-large {
  font-size: $font-size-lg;
}

// Global class for overlay.
.overlay-opener {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

pre {
  // Allows for text-wrap in <pre/>
  white-space: pre-wrap;
}

.nav-tabs {
  > .nav-link {
    padding: 0.75rem 1.5rem;
    color: $gray-600;
  }
}

// Remove the icon when enacting Bootstraps is-invalid class
.form-control.is-invalid {
  background-image: unset;
  background-position: unset;
  background-repeat: unset;
  background-size: unset;
}

// Global Styles for <var />
var {
  font-style: normal;
}

.commas-between {
  > *:after {
    content: ', ';
  }

  > *:last-child:after {
    content: '';
  }
}
