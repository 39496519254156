@import '../component-styles.scss';

$nav-active-color: rgba(6, 46, 89, 0.24);

.TopNav {
  .navbar {
    min-height: 3.125rem;
    background-image: linear-gradient(87deg, $blue, #1376f3);
  }

  .navbar-brand > img {
    height: $navbar-brand-height;
  }

  .navbar-toggler {
    border: 0;
  }

  .nav-link {
    align-items: center;
    color: $white !important;
    display: flex;
    font-weight: $font-weight-bold;
    height: $nav-link-height;

    > svg {
      margin-right: 0.625rem;
      opacity: 0.9;
    }

    &.current {
      background-color: $nav-active-color;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 1rem;
    }
  }

  @include media-breakpoint-up(sm) {
    .dropdown.show {
      background-color: $nav-active-color;
    }
  }
  .PremiumBadge {
    height: 'fit-content';
    cursor: pointer;
    background-color: $pink;
  }
}

.TopNav--navs {
  @include media-breakpoint-down(xs) {
    padding-bottom: 1rem;
  }
}

@include media-breakpoint-up(sm) {
  .help-dropdown,
  .notification-dropdown {
    .dropdown-toggle {
      // the same as px-2, but we need to put this
      // on the node with .dropdown-toggle and can't
      // do that manually with the way ReactBootstrap
      // renders the nodes from <NavDropdown />
      padding: 0 0.625rem !important;
    }
  }
}
