@import '../component-styles.scss';

input[type='time'] {
  width: 8rem;
  max-width: 8rem;
}

// Removes red "x" from input since the controls of the time input cover it on hover.
.is-invalid[type='time'] {
  background-image: unset;
  padding-right: $input-padding-x;
}
