@import '../../../../component-styles.scss';

.OnboardHeader {
  width: 100%;
  .account-dropdown {
    .nav-link {
      padding: 0;
    }
  }
  .user-gravatar {
    position: relative;
    height: 36px;
    width: 36px;
    .Gravatar {
      position: absolute;
    }
    .Gravatar img {
      border-radius: 50%;
    }
    .user-image {
      position: absolute;
      height: 36px;
      width: 36px;
    }
  }
}
