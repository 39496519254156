@import '../component-styles.scss';

.SelectableButton {
  display: flex;
  flex-direction: column;
  min-height: 2.625rem;

  &.success {
    border-color: $success !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  &.warning {
    border-color: $warning !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}
