.UpdateMeetingTypeModalSchedulingRulesSection {
  .media {
    .form-group {
      .btn {
        white-space: break-spaces;
      }
    }
  }

  // HACK
  // We do this to target the "Required Notice" section inputs
  // We don't need to apply this to the other translate nodes.
  div > .appointlet-translate {
    @apply tw-flex tw-items-center;
  }
}
