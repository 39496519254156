@import '../component-styles.scss';

.MeetingTypeImage {
  color: $gray-300;

  > img {
    height: 100%;
    width: 100%;
  }
}
