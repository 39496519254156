@import '../component-styles.scss';

.SettingsModalCalendarSection {
  .modal-title {
    line-height: unset;
  }
  img {
    height: 1.5625rem;
    width: 1.5625rem;
  }
  .ListColumnTitles {
    margin: unset;
    h6 {
      padding-left: unset;
      padding-right: unset;
    }
  }

  .card-body {
    .row {
      &:last-child {
        // Need !important flag to override bootstrap class on node.
        border-bottom: unset !important;
      }
    }
  }
}
