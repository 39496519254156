.EmojiPicker {
  .emoji-mart,
  .emoji-mart-bar {
    border: 0;
  }

  .emoji-mart-anchor-bar {
    display: none;
  }

  .emoji-mart-search {
    margin: 0 0 0.375rem 0;
  }

  .emoji-mart-anchors,
  .emoji-mart-emoji {
    cursor: pointer;
  }
}
